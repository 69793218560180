import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const TabsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '61px',
  [theme.breakpoints.down('md')]: {
    overflow: 'hidden',
  },
  [theme.breakpoints.up('gxl')]: {
    height: '3.2vw',
  },
}));
