import { ShineCSS, Wrapper } from './styles';
import { useState, MouseEvent, useEffect } from 'react';
import { useDrawerContext } from '../../context';
import { PopperCustom } from '../UI/PopperCustom/PopperCustom';
import { PopperPlacementType } from '@mui/base/Popper/Popper.types';
import { useMediaQueryCustom } from '../../hooks';

interface HoverBlockForSpinnerProps {
  width: number;
  height: number;
  top: number;
  right: number;
  enabledPopper?: boolean;
  popperMenuText?: string;
  popoverPlacement: PopperPlacementType;
  setIsClosePopover: () => void;
}

export const HoverBlockForSpinner = ({
  width,
  height,
  top,
  right,
  enabledPopper = false,
  popperMenuText = '',
  popoverPlacement,
  setIsClosePopover,
}: HoverBlockForSpinnerProps) => {
  const { isGxl } = useMediaQueryCustom();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const { openDrawer } = useDrawerContext();

  const onMouseOver = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setIsClosePopover();
  };

  const onMouseLeave = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!enabledPopper) return;
    setOpen(false);
  }, [openDrawer]);

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  return (
    <Wrapper
      coordinates={{ width, height, top, right }}
      {...(enabledPopper && { onMouseOver, onMouseLeave })}
    >
      <ShineCSS />
      <PopperCustom
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={popoverPlacement}
        popperMenuText={popperMenuText}
        wrapperSx={{
          [popoverPlacement === 'bottom' ? 'top' : 'bottom']: '-45px !important',
          maxWidth: isGxl ? '340px' : '20vw',
        }}
        popperSx={{ width: 'fit-content' }}
      />
    </Wrapper>
  );
};
