import { Button, List, Stack, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: '39px',
  marginBottom: '130px',
  userSelect: 'none',
}));

// for download pdf list
export const BlockForDownloadPdfCSS = styled(Stack)(({ theme }) => ({
  background: '#002960',
  padding: '20px',
  margin: '-20px',
  gap: '30px',
  p: {
    fontSize: '16px',
  },
  [theme.breakpoints.up('gxl')]: {
    h4: {
      fontSize: '1.8vw',
    },
    h5: {
      fontSize: '1.25vw',
    },
    h6: {
      fontSize: '1.04vw',
    },
    p: {
      fontSize: '1vw',
    },
    ul: {
      fontSize: '1vw',
      li: {
        paddingTop: '0.42vw',
        paddingBottom: '0.42vw',
        height: '2vw',
      },
    },
  },
}));

export const ListCSS = styled(List)(() => ({
  '& ::before': {
    content: '"●"',
    marginRight: '10px',
  },
}));

export const ButtonCSS = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  [theme.breakpoints.up('gxl')]: {
    fontSize: '0.83vw',
    padding: '0.3vw 0.6vw',
    height: 'auto',
    '.MuiSvgIcon-root': {
      fontSize: '1vw',
    },
  },
}));
