import { useEffect, useRef, useState } from 'react';
import left from './left_arrow.json';
import right from './right_arrow.json';
import bottom from './bottom_arrow.json';
import Lottie, { AnimationItem } from 'lottie-web';
import { buttons } from '../../constants';
import { PopoverButton } from '../PopoverButton';
import { SpinnerBlockCSS, Wrapper } from './styles';
import { HoverBlockForSpinner } from '../HoverBlockForSpinner/HoverBlockForSpinner';
import { findNestedGroups, getPopperMenuText, getPopperPlacement } from '../../utils';
import { useMediaQueryCustom } from '../../hooks';
import { PopperPlacementType } from '@mui/base/Popper/Popper.types';

type Coordinates = {
  enabledPopper?: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  popperMenuText: string;
  popoverPlacement: PopperPlacementType;
};

export const SpinnerAnimation = () => {
  const { isMobile } = useMediaQueryCustom();

  const svgRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [newCoord, setNewCoord] = useState<Coordinates[]>([]);

  const [isClosePopover, setIsClosePopover] = useState(false);

  useEffect(() => {
    const container = svgRef.current as HTMLDivElement;
    const animations = [left, right, bottom];
    let currentAnimationIndex = 0;
    let currentAnimationInstance: AnimationItem | null = null;

    const playNextAnimation = () => {
      if (currentAnimationInstance) {
        currentAnimationInstance.destroy();
      }

      const currentAnimation = animations[currentAnimationIndex];
      currentAnimationInstance = Lottie.loadAnimation({
        container,
        animationData: currentAnimation,
        loop: false,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
        },
        renderer: 'svg',
      });

      currentAnimationInstance.addEventListener('complete', () => {
        currentAnimationIndex = (currentAnimationIndex + 1) % animations.length;
        playNextAnimation();
      });
    };

    playNextAnimation();
  }, []);

  const createHoverEffect = () => {
    const svgElement = svgRef.current;
    const results: Element[] = [];

    if (svgElement) {
      const firstGroup = svgElement.querySelector('g');
      if (firstGroup) {
        findNestedGroups(firstGroup, results);
      }
    }
    const filteredResults = results.filter((_, index) => [13, 14, 16, 18].includes(index));
    const parentRect = (parentRef?.current as HTMLDivElement)?.getBoundingClientRect();

    filteredResults.forEach((item, index) => {
      const childsRect = item.getBoundingClientRect();

      setNewCoord((prevState) => [
        ...prevState,
        {
          enabledPopper: true, // index === 0 будем добавлять Popper так как это центральный эдемент
          x: childsRect.x - parentRect.x,
          y: childsRect.y - parentRect.y,
          width: childsRect.width,
          height: childsRect.height,
          popperMenuText: getPopperMenuText(index),
          popoverPlacement: getPopperPlacement(index),
        },
      ]);
    });
  };

  useEffect(() => {
    createHoverEffect();
  }, []);

  const handleSetIsClosePopover = () => {
    setIsClosePopover((prevState) => !prevState);
  };

  return (
    <Wrapper ref={parentRef}>
      <SpinnerBlockCSS ref={svgRef} id={'svg-container'} />
      {buttons.map((button, index) => (
        <PopoverButton
          key={index}
          text={button.text}
          containerStyles={button[!isMobile ? 'containerStyles' : 'isMobileContainerStyles']}
          variant={'popup'}
          popoverMenuText={button.popoverMenuText}
          popoverPlacement={button[!isMobile ? 'popoverPlacement' : 'isMobilePopoverPlacement']}
          popoverStyles={button[!isMobile ? 'popoverStyles' : 'isMobilePopoverStyles']}
          isClosePopover={isClosePopover}
        />
      ))}
      {newCoord.map((item, i) => {
        return (
          <HoverBlockForSpinner
            key={i}
            enabledPopper={item.enabledPopper}
            width={item.width + 5}
            height={item.height + 5}
            top={item.y - 3}
            right={item.x - 3}
            popperMenuText={item.popperMenuText}
            popoverPlacement={item.popoverPlacement}
            setIsClosePopover={handleSetIsClosePopover}
          />
        );
      })}
    </Wrapper>
  );
};
