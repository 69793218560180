import React from 'react';
import { IconComponentPropsType } from '../../types';
import { palette } from '../../theme/foundations';

export const TriangleArrowIcon = ({
  width = '20px',
  height = '16px',
  style,
  color = palette.white100,
}: IconComponentPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        d='M0.196664 2.51821C-0.291848 1.85807 0.185689 0.929688 1.01376 0.929688H18.9862C19.8143 0.929688 20.2919 1.85807 19.8033 2.51821L10.8171 14.6615C10.4129 15.2078 9.58715 15.2078 9.18291 14.6615L0.196664 2.51821Z'
        fill={color}
      />
    </svg>
  );
};
