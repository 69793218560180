import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, FormLabel, IconButton, InputAdornment, SxProps } from '@mui/material';
import React, { forwardRef, ReactNode, useState } from 'react';
import { Controller, FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form';
import { palette } from '../../../theme/foundations';
import { TextFieldCSS, TypographyCSS } from './styles';

export interface ITextFieldCustomProps {
  value: string;
  onChange: (val: object) => void;

  label?: string;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  readOnly?: boolean;
  size?: 'medium' | 'small';
  type?: React.InputHTMLAttributes<unknown>['type'];
  mb?: string | number;
  sx?: SxProps;
  multiline?: boolean;
  fullWidth?: boolean;
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
}

interface ITextFieldRHFProps extends Omit<ITextFieldCustomProps, 'value' | 'onChange'> {
  name: string;
}

export const TextFieldCustom = forwardRef(
  (
    {
      value,
      onChange,
      label,
      minRows,
      multiline,
      readOnly,
      size,
      type,
      mb,
      sx,
      maxRows,
      fullWidth = true,
      errorMessage,
      placeholder,
    }: ITextFieldCustomProps,
    ref,
  ) => {
    const [viewPassword, setViewPassword] = useState(false);

    return (
      <Box mb={mb} sx={sx}>
        {label ? (
          <FormLabel>
            <TypographyCSS>{label}</TypographyCSS>
          </FormLabel>
        ) : null}
        <TextFieldCSS
          inputRef={ref}
          value={value}
          onChange={onChange}
          size={size}
          type={viewPassword ? 'text' : type}
          error={!!errorMessage}
          fullWidth={fullWidth}
          multiline={multiline}
          minRows={minRows}
          maxRows={maxRows}
          placeholder={placeholder}
          helperText={errorMessage as ReactNode}
          label={''}
          sx={{
            '.MuiOutlinedInput-root': {
              outline: errorMessage ? `2px solid ${palette.error.main}` : '',
            },
            '.MuiFormHelperText-root': {
              fontSize: {
                gxl: '0.63vw',
              },
              top: {
                gxl: '-1.35vw',
              },
            },
          }}
          InputProps={{
            readOnly,
            endAdornment: (
              <InputAdornment position='start'>
                {type === 'password' ? (
                  <IconButton
                    onClick={() => {
                      setViewPassword(!viewPassword);
                    }}
                  >
                    {viewPassword ? (
                      <VisibilityOffIcon color={'secondary'} />
                    ) : (
                      <VisibilityIcon color={'secondary'} />
                    )}
                  </IconButton>
                ) : null}
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  },
);

export const TextFieldRHF = ({ name, ...rest }: ITextFieldRHFProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        return (
          <TextFieldCustom
            value={field.value}
            onChange={field.onChange}
            errorMessage={formState.errors?.[name]?.message}
            {...rest}
          />
        );
      }}
    />
  );
};
