import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import { palette } from '../../theme/foundations';

const initialCircleStyles = {
  width: '162px',
  height: '162px',
  borderRadius: '50%',
  border: '3px solid',
  borderColor: palette.blue500,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const BankerSpinnerCircle = styled(Box)(() => ({
  ...initialCircleStyles,
  left: '0',
  bottom: '52px',
  position: 'absolute',
  boxShadow: '0px 9.584471702575684px 35.941768646240234px 0px rgba(0, 0, 0, 0.25)',
}));

export const BankSpinnerCircle = styled(Box)(() => ({
  ...initialCircleStyles,
  left: '234px',
  top: '64px',
  position: 'absolute',
  '& svg': {
    marginTop: '-15px',
  },
  boxShadow: '0px 9.584471702575684px 35.941768646240234px 0px rgba(0, 0, 0, 0.25)',
}));

export const WorkerSpinnerCircle = styled(Box)(() => ({
  ...initialCircleStyles,
  right: '0',
  bottom: '52px',
  position: 'absolute',
  '& svg': {
    marginTop: '-5px',
  },
  boxShadow: '0px 9.584471702575684px 35.941768646240234px 0px rgba(0, 0, 0, 0.25)',
}));

export const LogoSpinnerCircle = styled(Box)(() => ({
  ...initialCircleStyles,
  width: '184px',
  height: '184px',
  left: '223px',
  top: '257px',
  position: 'absolute',
}));

export const SpinnerWrapper = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}));

export const BottomToTopArrowIconWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '172px',
  left: '78px',
  zIndex: 5,
}));

export const TopToBottomArrowIconWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '172px',
  left: '379px',
  zIndex: 5,
}));

export const RightToLeftArrowIconWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '438px',
  left: '151.09px',
  zIndex: 5,
}));
