import { IconComponentPropsType } from '../../types';
import { palette } from '../../theme/foundations';

export const LogoIcon = ({
  width = '485px',
  height = '30px',
  style,
  color = palette.white,
}: IconComponentPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 485 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        d='M24.61 9.35731C25.1515 8.52697 25.8284 7.87715 26.6406 7.40783C27.4529 6.92047 28.3555 6.67678 29.3482 6.67678V11.5775H28.0757C26.9204 11.5775 26.054 11.8302 25.4764 12.3357C24.8988 12.823 24.61 13.6895 24.61 14.935V21.9477H19.98V6.83924H24.61V9.35731Z'
        fill={color}
      />
      <path
        d='M33.8898 5.26883C33.0775 5.26883 32.4096 5.03417 31.8862 4.56485C31.3808 4.07748 31.128 3.48181 31.128 2.77784C31.128 2.05581 31.3808 1.46014 31.8862 0.990818C32.4096 0.50345 33.0775 0.259766 33.8898 0.259766C34.684 0.259766 35.3338 0.50345 35.8393 0.990818C36.3627 1.46014 36.6245 2.05581 36.6245 2.77784C36.6245 3.48181 36.3627 4.07748 35.8393 4.56485C35.3338 5.03417 34.684 5.26883 33.8898 5.26883ZM36.1912 6.83924V21.9477H31.5613V6.83924H36.1912Z'
        fill={color}
      />
      <path
        d='M47.9671 18.0216V21.9477H45.6115C43.9328 21.9477 42.6242 21.5415 41.6855 20.7292C40.7469 19.8989 40.2776 18.5541 40.2776 16.6949V10.684H38.4364V6.83924H40.2776V3.1569H44.9076V6.83924H47.9401V10.684H44.9076V16.7491C44.9076 17.2003 45.0159 17.5252 45.2325 17.7238C45.4491 17.9224 45.8101 18.0216 46.3155 18.0216H47.9671Z'
        fill={color}
      />
      <path
        d='M58.9667 18.0216V21.9477H56.6111C54.9324 21.9477 53.6237 21.5415 52.6851 20.7292C51.7465 19.8989 51.2772 18.5541 51.2772 16.6949V10.684H49.436V6.83924H51.2772V3.1569H55.9071V6.83924H58.9396V10.684H55.9071V16.7491C55.9071 17.2003 56.0154 17.5252 56.232 17.7238C56.4486 17.9224 56.8097 18.0216 57.3151 18.0216H58.9667Z'
        fill={color}
      />
      <path
        d='M67.6919 22.1643C66.3742 22.1643 65.2009 21.9386 64.172 21.4874C63.1432 21.0361 62.3309 20.4224 61.7352 19.6462C61.1395 18.852 60.8056 17.9675 60.7334 16.9927H65.3092C65.3634 17.5162 65.6071 17.9404 66.0403 18.2653C66.4735 18.5902 67.006 18.7527 67.6377 18.7527C68.2154 18.7527 68.6576 18.6444 68.9645 18.4278C69.2894 18.1931 69.4518 17.8953 69.4518 17.5343C69.4518 17.101 69.2262 16.7852 68.7749 16.5866C68.3237 16.37 67.5926 16.1353 66.5818 15.8826C65.4988 15.6299 64.5962 15.3682 63.8742 15.0974C63.1522 14.8086 62.5294 14.3664 62.006 13.7707C61.4825 13.157 61.2208 12.3357 61.2208 11.3068C61.2208 10.4403 61.4554 9.65514 61.9247 8.95117C62.4121 8.22914 63.1161 7.66054 64.0367 7.24538C64.9753 6.83021 66.0854 6.62263 67.367 6.62263C69.2623 6.62263 70.7515 7.09195 71.8345 8.03058C72.9356 8.96922 73.5674 10.2147 73.7298 11.7671H69.4518C69.3796 11.2436 69.145 10.8284 68.7479 10.5216C68.3688 10.2147 67.8634 10.0613 67.2316 10.0613C66.6901 10.0613 66.2749 10.1696 65.9861 10.3862C65.6973 10.5848 65.5529 10.8645 65.5529 11.2256C65.5529 11.6588 65.7785 11.9837 66.2298 12.2003C66.6991 12.4169 67.4211 12.6335 68.3959 12.8501C69.515 13.1389 70.4266 13.4277 71.1305 13.7165C71.8345 13.9873 72.4482 14.4386 72.9717 15.0703C73.5132 15.6841 73.793 16.5144 73.811 17.5613C73.811 18.4458 73.5583 19.2401 73.0529 19.944C72.5656 20.63 71.8526 21.1715 70.9139 21.5686C69.9933 21.9657 68.9193 22.1643 67.6919 22.1643Z'
        fill={color}
      />
      <path
        d='M78.9722 5.26883C78.1599 5.26883 77.492 5.03417 76.9686 4.56485C76.4631 4.07748 76.2104 3.48181 76.2104 2.77784C76.2104 2.05581 76.4631 1.46014 76.9686 0.990818C77.492 0.50345 78.1599 0.259766 78.9722 0.259766C79.7664 0.259766 80.4162 0.50345 80.9216 0.990818C81.4451 1.46014 81.7068 2.05581 81.7068 2.77784C81.7068 3.48181 81.4451 4.07748 80.9216 4.56485C80.4162 5.03417 79.7664 5.26883 78.9722 5.26883ZM81.2736 6.83924V21.9477H76.6436V6.83924H81.2736Z'
        fill={color}
      />
      <path
        d='M83.7083 14.3934C83.7083 12.823 84.0242 11.4512 84.656 10.2779C85.3058 9.1046 86.1993 8.20206 87.3365 7.57029C88.4917 6.93852 89.8094 6.62263 91.2896 6.62263C93.1849 6.62263 94.7643 7.11902 96.0279 8.11181C97.3094 9.1046 98.1488 10.5035 98.5459 12.3086H93.6181C93.2029 11.1533 92.3997 10.5757 91.2084 10.5757C90.36 10.5757 89.6831 10.9097 89.1777 11.5775C88.6722 12.2274 88.4195 13.166 88.4195 14.3934C88.4195 15.6209 88.6722 16.5686 89.1777 17.2364C89.6831 17.8863 90.36 18.2112 91.2084 18.2112C92.3997 18.2112 93.2029 17.6335 93.6181 16.4783H98.5459C98.1488 18.2473 97.3094 19.6372 96.0279 20.648C94.7463 21.6588 93.1668 22.1643 91.2896 22.1643C89.8094 22.1643 88.4917 21.8484 87.3365 21.2166C86.1993 20.5848 85.3058 19.6823 84.656 18.509C84.0242 17.3357 83.7083 15.9639 83.7083 14.3934Z'
        fill={color}
      />
      <path
        d='M110.31 6.67678C112.042 6.67678 113.432 7.2544 114.479 8.40965C115.526 9.54684 116.05 11.1172 116.05 13.1209V21.9477H111.447V13.7436C111.447 12.7328 111.185 11.9476 110.662 11.388C110.138 10.8284 109.434 10.5487 108.55 10.5487C107.665 10.5487 106.961 10.8284 106.438 11.388C105.914 11.9476 105.652 12.7328 105.652 13.7436V21.9477H101.023V1.9114H105.652V8.86994C106.122 8.20206 106.763 7.66957 107.575 7.27245C108.387 6.87534 109.299 6.67678 110.31 6.67678Z'
        fill={color}
      />
      <path
        d='M133.509 14.1498C133.509 14.583 133.482 15.0342 133.428 15.5036H122.949C123.022 16.4422 123.319 17.1642 123.843 17.6696C124.384 18.157 125.043 18.4007 125.819 18.4007C126.975 18.4007 127.778 17.9133 128.229 16.9386H133.157C132.904 17.9314 132.444 18.8249 131.776 19.6191C131.126 20.4133 130.305 21.0361 129.312 21.4874C128.319 21.9386 127.209 22.1643 125.982 22.1643C124.502 22.1643 123.184 21.8484 122.029 21.2166C120.873 20.5848 119.971 19.6823 119.321 18.509C118.671 17.3357 118.346 15.9639 118.346 14.3934C118.346 12.823 118.662 11.4512 119.294 10.2779C119.944 9.1046 120.846 8.20206 122.002 7.57029C123.157 6.93852 124.484 6.62263 125.982 6.62263C127.444 6.62263 128.744 6.92949 129.881 7.54321C131.018 8.15694 131.902 9.03239 132.534 10.1696C133.184 11.3068 133.509 12.6335 133.509 14.1498ZM128.771 12.9313C128.771 12.1371 128.5 11.5053 127.958 11.036C127.417 10.5667 126.74 10.332 125.928 10.332C125.151 10.332 124.493 10.5577 123.951 11.0089C123.428 11.4602 123.103 12.101 122.976 12.9313H128.771Z'
        fill={color}
      />
      <path
        d='M140.581 9.35731C141.123 8.52697 141.8 7.87715 142.612 7.40783C143.424 6.92047 144.327 6.67678 145.32 6.67678V11.5775H144.047C142.892 11.5775 142.025 11.8302 141.448 12.3357C140.87 12.823 140.581 13.6895 140.581 14.935V21.9477H135.951V6.83924H140.581V9.35731Z'
        fill={color}
      />
      <path
        d='M156.82 6.67678C158.553 6.67678 159.943 7.2544 160.989 8.40965C162.036 9.54684 162.56 11.1172 162.56 13.1209V21.9477H157.957V13.7436C157.957 12.7328 157.695 11.9476 157.172 11.388C156.648 10.8284 155.944 10.5487 155.06 10.5487C154.175 10.5487 153.471 10.8284 152.948 11.388C152.424 11.9476 152.163 12.7328 152.163 13.7436V21.9477H147.533V1.9114H152.163V8.86994C152.632 8.20206 153.273 7.66957 154.085 7.27245C154.897 6.87534 155.809 6.67678 156.82 6.67678Z'
        fill={color}
      />
      <path
        d='M180.019 14.1498C180.019 14.583 179.992 15.0342 179.938 15.5036H169.46C169.532 16.4422 169.83 17.1642 170.353 17.6696C170.895 18.157 171.553 18.4007 172.33 18.4007C173.485 18.4007 174.288 17.9133 174.739 16.9386H179.667C179.414 17.9314 178.954 18.8249 178.286 19.6191C177.636 20.4133 176.815 21.0361 175.822 21.4874C174.83 21.9386 173.719 22.1643 172.492 22.1643C171.012 22.1643 169.694 21.8484 168.539 21.2166C167.384 20.5848 166.481 19.6823 165.831 18.509C165.182 17.3357 164.857 15.9639 164.857 14.3934C164.857 12.823 165.173 11.4512 165.804 10.2779C166.454 9.1046 167.357 8.20206 168.512 7.57029C169.667 6.93852 170.994 6.62263 172.492 6.62263C173.954 6.62263 175.254 6.92949 176.391 7.54321C177.528 8.15694 178.413 9.03239 179.044 10.1696C179.694 11.3068 180.019 12.6335 180.019 14.1498ZM175.281 12.9313C175.281 12.1371 175.01 11.5053 174.469 11.036C173.927 10.5667 173.25 10.332 172.438 10.332C171.662 10.332 171.003 10.5577 170.461 11.0089C169.938 11.4602 169.613 12.101 169.487 12.9313H175.281Z'
        fill={color}
      />
      <path
        d='M184.79 5.26883C183.978 5.26883 183.31 5.03417 182.787 4.56485C182.281 4.07748 182.028 3.48181 182.028 2.77784C182.028 2.05581 182.281 1.46014 182.787 0.990818C183.31 0.50345 183.978 0.259766 184.79 0.259766C185.584 0.259766 186.234 0.50345 186.74 0.990818C187.263 1.46014 187.525 2.05581 187.525 2.77784C187.525 3.48181 187.263 4.07748 186.74 4.56485C186.234 5.03417 185.584 5.26883 184.79 5.26883ZM187.092 6.83924V21.9477H182.462V6.83924H187.092Z'
        fill={color}
      />
      <path
        d='M198.868 18.0216V21.9477H196.512C194.833 21.9477 193.525 21.5415 192.586 20.7292C191.647 19.8989 191.178 18.5541 191.178 16.6949V10.684H189.337V6.83924H191.178V3.1569H195.808V6.83924H198.84V10.684H195.808V16.7491C195.808 17.2003 195.916 17.5252 196.133 17.7238C196.349 17.9224 196.71 18.0216 197.216 18.0216H198.868Z'
        fill={color}
      />
      <path
        d='M215.688 14.1498C215.688 14.583 215.661 15.0342 215.607 15.5036H205.129C205.201 16.4422 205.499 17.1642 206.022 17.6696C206.564 18.157 207.223 18.4007 207.999 18.4007C209.154 18.4007 209.957 17.9133 210.409 16.9386H215.336C215.084 17.9314 214.623 18.8249 213.956 19.6191C213.306 20.4133 212.484 21.0361 211.492 21.4874C210.499 21.9386 209.389 22.1643 208.161 22.1643C206.681 22.1643 205.363 21.8484 204.208 21.2166C203.053 20.5848 202.15 19.6823 201.501 18.509C200.851 17.3357 200.526 15.9639 200.526 14.3934C200.526 12.823 200.842 11.4512 201.474 10.2779C202.123 9.1046 203.026 8.20206 204.181 7.57029C205.336 6.93852 206.663 6.62263 208.161 6.62263C209.623 6.62263 210.923 6.92949 212.06 7.54321C213.197 8.15694 214.082 9.03239 214.714 10.1696C215.363 11.3068 215.688 12.6335 215.688 14.1498ZM210.95 12.9313C210.95 12.1371 210.679 11.5053 210.138 11.036C209.596 10.5667 208.919 10.332 208.107 10.332C207.331 10.332 206.672 10.5577 206.131 11.0089C205.607 11.4602 205.282 12.101 205.156 12.9313H210.95Z'
        fill={color}
      />
      <path
        d='M227.337 6.67678C229.106 6.67678 230.514 7.2544 231.561 8.40965C232.626 9.54684 233.158 11.1172 233.158 13.1209V21.9477H228.555V13.7436C228.555 12.7328 228.293 11.9476 227.77 11.388C227.246 10.8284 226.543 10.5487 225.658 10.5487C224.774 10.5487 224.07 10.8284 223.546 11.388C223.023 11.9476 222.761 12.7328 222.761 13.7436V21.9477H218.131V6.83924H222.761V8.84286C223.23 8.17499 223.862 7.65152 224.656 7.27245C225.45 6.87534 226.344 6.67678 227.337 6.67678Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.11332 2.64062H0.00285161V6.84905H8.11332C9.7655 6.84905 11.1238 7.41162 12.069 8.37571C13.0147 9.34026 13.5657 10.7261 13.5657 12.407C13.5657 13.5108 13.192 14.9447 12.3217 16.0969C11.4586 17.2396 10.1046 18.1103 8.11332 18.1103H4.2084V10.0689H0V22.3159H8.11332C13.6249 22.3159 17.7739 18.0366 17.7741 12.3845C17.777 10.0468 17.0263 7.61293 15.4371 5.75935C13.8429 3.89986 11.4183 2.64062 8.11332 2.64062ZM4.2084 18.361V18.1103H3.9577V18.361H4.2084Z'
        fill={color}
      />
      <path
        d='M278.508 14.4352C278.508 12.8648 278.824 11.4929 279.455 10.3196C280.105 9.14632 280.999 8.24378 282.136 7.61201C283.291 6.98024 284.609 6.66435 286.089 6.66435C287.984 6.66435 289.564 7.16074 290.827 8.15353C292.109 9.14632 292.948 10.5452 293.345 12.3503H288.417C288.002 11.1951 287.199 10.6174 286.008 10.6174C285.159 10.6174 284.482 10.9514 283.977 11.6193C283.472 12.2691 283.219 13.2077 283.219 14.4352C283.219 15.6626 283.472 16.6103 283.977 17.2781C284.482 17.928 285.159 18.2529 286.008 18.2529C287.199 18.2529 288.002 17.6753 288.417 16.52H293.345C292.948 18.289 292.109 19.6789 290.827 20.6897C289.546 21.7006 287.966 22.206 286.089 22.206C284.609 22.206 283.291 21.8901 282.136 21.2583C280.999 20.6265 280.105 19.724 279.455 18.5507C278.824 17.3774 278.508 16.0056 278.508 14.4352Z'
        fill={color}
      />
      <path
        d='M305.109 6.7185C306.842 6.7185 308.232 7.29612 309.279 8.45137C310.326 9.58856 310.849 11.159 310.849 13.1626V21.9894H306.246V13.7853C306.246 12.7745 305.984 11.9893 305.461 11.4297C304.937 10.8702 304.233 10.5904 303.349 10.5904C302.464 10.5904 301.761 10.8702 301.237 11.4297C300.714 11.9893 300.452 12.7745 300.452 13.7853V21.9894H295.822V1.95312H300.452V8.91166C300.921 8.24378 301.562 7.71129 302.374 7.31417C303.187 6.91706 304.098 6.7185 305.109 6.7185Z'
        fill={color}
      />
      <path
        d='M313.146 14.4081C313.146 12.8557 313.435 11.4929 314.012 10.3196C314.608 9.14632 315.411 8.24378 316.422 7.61201C317.433 6.98024 318.561 6.66435 319.806 6.66435C320.871 6.66435 321.801 6.88096 322.595 7.31417C323.408 7.74739 324.03 8.31599 324.463 9.01996V6.88096H329.093V21.9894H324.463V19.8504C324.012 20.5543 323.38 21.1229 322.568 21.5562C321.774 21.9894 320.844 22.206 319.779 22.206C318.552 22.206 317.433 21.8901 316.422 21.2583C315.411 20.6085 314.608 19.6969 314.012 18.5236C313.435 17.3323 313.146 15.9604 313.146 14.4081ZM324.463 14.4352C324.463 13.2799 324.139 12.3684 323.489 11.7005C322.857 11.0326 322.081 10.6987 321.16 10.6987C320.24 10.6987 319.454 11.0326 318.805 11.7005C318.173 12.3503 317.857 13.2528 317.857 14.4081C317.857 15.5633 318.173 16.4839 318.805 17.1698C319.454 17.8377 320.24 18.1717 321.16 18.1717C322.081 18.1717 322.857 17.8377 323.489 17.1698C324.139 16.502 324.463 15.5904 324.463 14.4352Z'
        fill={color}
      />
      <path
        d='M341.649 6.7185C343.418 6.7185 344.826 7.29612 345.873 8.45137C346.938 9.58856 347.47 11.159 347.47 13.1626V21.9894H342.867V13.7853C342.867 12.7745 342.606 11.9893 342.082 11.4297C341.559 10.8702 340.855 10.5904 339.97 10.5904C339.086 10.5904 338.382 10.8702 337.858 11.4297C337.335 11.9893 337.073 12.7745 337.073 13.7853V21.9894H332.443V6.88096H337.073V8.88458C337.542 8.21671 338.174 7.69324 338.968 7.31417C339.763 6.91706 340.656 6.7185 341.649 6.7185Z'
        fill={color}
      />
      <path
        d='M356.428 6.66435C357.493 6.66435 358.422 6.88096 359.216 7.31417C360.029 7.74739 360.651 8.31599 361.085 9.01996V6.88096H365.715V21.9623C365.715 23.3522 365.435 24.6067 364.875 25.7259C364.334 26.8631 363.494 27.7656 362.357 28.4335C361.238 29.1013 359.839 29.4353 358.16 29.4353C355.922 29.4353 354.108 28.9028 352.718 27.8378C351.328 26.7909 350.534 25.3648 350.336 23.5598H354.911C355.056 24.1374 355.399 24.5887 355.94 24.9136C356.482 25.2565 357.15 25.428 357.944 25.428C358.901 25.428 359.659 25.1482 360.218 24.5887C360.796 24.0472 361.085 23.1717 361.085 21.9623V19.8233C360.633 20.5273 360.011 21.1049 359.216 21.5562C358.422 21.9894 357.493 22.206 356.428 22.206C355.182 22.206 354.054 21.8901 353.043 21.2583C352.032 20.6085 351.229 19.6969 350.633 18.5236C350.056 17.3323 349.767 15.9604 349.767 14.4081C349.767 12.8557 350.056 11.4929 350.633 10.3196C351.229 9.14632 352.032 8.24378 353.043 7.61201C354.054 6.98024 355.182 6.66435 356.428 6.66435ZM361.085 14.4352C361.085 13.2799 360.76 12.3684 360.11 11.7005C359.478 11.0326 358.702 10.6987 357.781 10.6987C356.861 10.6987 356.076 11.0326 355.426 11.7005C354.794 12.3503 354.478 13.2528 354.478 14.4081C354.478 15.5633 354.794 16.4839 355.426 17.1698C356.076 17.8377 356.861 18.1717 357.781 18.1717C358.702 18.1717 359.478 17.8377 360.11 17.1698C360.76 16.502 361.085 15.5904 361.085 14.4352Z'
        fill={color}
      />
      <path
        d='M383.306 14.1915C383.306 14.6247 383.279 15.076 383.225 15.5453H372.747C372.819 16.4839 373.117 17.2059 373.64 17.7114C374.182 18.1987 374.84 18.4424 375.617 18.4424C376.772 18.4424 377.575 17.955 378.026 16.9803H382.954C382.701 17.9731 382.241 18.8666 381.573 19.6608C380.923 20.4551 380.102 21.0778 379.109 21.5291C378.117 21.9803 377.006 22.206 375.779 22.206C374.299 22.206 372.981 21.8901 371.826 21.2583C370.671 20.6265 369.768 19.724 369.118 18.5507C368.469 17.3774 368.144 16.0056 368.144 14.4352C368.144 12.8648 368.46 11.4929 369.091 10.3196C369.741 9.14632 370.644 8.24378 371.799 7.61201C372.954 6.98024 374.281 6.66435 375.779 6.66435C377.241 6.66435 378.541 6.97121 379.678 7.58494C380.815 8.19866 381.7 9.07412 382.331 10.2113C382.981 11.3485 383.306 12.6752 383.306 14.1915ZM378.568 12.9731C378.568 12.1788 378.297 11.5471 377.756 11.0777C377.214 10.6084 376.537 10.3738 375.725 10.3738C374.949 10.3738 374.29 10.5994 373.748 11.0507C373.225 11.5019 372.9 12.1427 372.774 12.9731H378.568Z'
        fill={color}
      />
      <path d='M256.69 2.68555H245.158V6.69681H256.69V2.68555Z' fill={color} />
      <path d='M256.69 10.2067H245.158V14.7193H256.69V10.2067Z' fill={color} />
      <path d='M245.158 18.2292H256.69V22.2404H245.158V18.2292Z' fill={color} />
      <path
        d='M277.467 2.68555H272.263L268.631 7.56871L269.133 8.24426L271.23 11.0641L277.467 2.68555Z'
        fill={color}
      />
      <path
        d='M277.447 22.2402L271.23 13.8842L268.631 17.3797L272.246 22.2402H277.447Z'
        fill={color}
      />
      <path
        d='M262.897 2.68555H257.696L264.979 12.4729L257.713 22.2404H262.916L270.183 12.4728L262.897 2.68555Z'
        fill={color}
      />
      <path
        d='M407.911 8.54529C407.911 10.1142 407.37 11.4216 406.288 12.4676C405.224 13.4955 403.592 14.0094 401.392 14.0094H397.767V21.881H395.306V3.02705H401.392C403.52 3.02705 405.134 3.541 406.234 4.56891C407.352 5.59682 407.911 6.92228 407.911 8.54529ZM401.392 11.9807C402.763 11.9807 403.773 11.6831 404.422 11.088C405.071 10.4929 405.396 9.64533 405.396 8.54529C405.396 6.21897 404.061 5.05581 401.392 5.05581H397.767V11.9807H401.392Z'
        fill={color}
      />
      <path
        d='M425.226 21.881L420.736 14.1717H417.76V21.881H415.299V3.02705H421.385C422.81 3.02705 424.009 3.2705 424.983 3.75741C425.974 4.24431 426.714 4.90253 427.201 5.73207C427.688 6.56161 427.931 7.50836 427.931 8.57234C427.931 9.87075 427.552 11.0159 426.795 12.0077C426.056 12.9996 424.937 13.6578 423.441 13.9824L428.174 21.881H425.226ZM417.76 12.1971H421.385C422.719 12.1971 423.72 11.8725 424.387 11.2233C425.055 10.556 425.388 9.67238 425.388 8.57234C425.388 7.45426 425.055 6.58866 424.387 5.97552C423.738 5.36238 422.737 5.05581 421.385 5.05581H417.76V12.1971Z'
        fill={color}
      />
      <path d='M438.545 3.02705V21.881H436.084V3.02705H438.545Z' fill={color} />
      <path
        d='M466.193 3.1623V21.881H463.732V7.92313L457.51 21.881H455.779L449.53 7.89608V21.881H447.069V3.1623H449.72L456.644 18.635L463.569 3.1623H466.193Z'
        fill={color}
      />
      <path
        d='M477.157 5.02876V11.3315H484.028V13.3602H477.157V19.8523H484.839V21.881H474.696V3H484.839V5.02876H477.157Z'
        fill={color}
      />
    </svg>
  );
};
