import { SnackbarCustom } from 'components/SnackbarCustom/SnackbarCustom';
import React, { useState } from 'react';

type ValueT = {
  openDrawer: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
};

type Props = {
  children: React.ReactNode;
};

export const DrawerContext = React.createContext<ValueT>({
  openDrawer: false,
  handleDrawerClose: () => {},
  handleDrawerOpen: () => {},
});

export function useDrawerContext() {
  const drawerContext = React.useContext(DrawerContext);

  if (!drawerContext) {
    throw new Error('useDrawerContext must be used within a DrawerProvider');
  }
  return drawerContext;
}

export const DrawerProvider = ({ children }: Props) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const value = { openDrawer, handleDrawerOpen, handleDrawerClose };

  return (
    <DrawerContext.Provider value={value}>
      {children}
      <SnackbarCustom />
    </DrawerContext.Provider>
  );
};
