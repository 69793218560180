import { IconComponentPropsType } from '../../types';

export const LinkedinIcon = ({
  width = '32px',
  height = '32px',
  style,
}: IconComponentPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        d='M0 5.33333C0 2.38782 2.38391 0 5.32462 0H26.0087C28.9494 0 31.3333 2.38781 31.3333 5.33333V26.6667C31.3333 29.6122 28.9494 32 26.0087 32H5.32462C2.38391 32 0 29.6122 0 26.6667V5.33333Z'
        fill='#0B5BBC'
      />
      <path
        d='M22.0559 27.5022H26.7741V17.9706C26.7741 13.2632 23.9291 11.6577 21.2963 11.6577C18.8619 11.6577 17.2082 13.2774 16.7516 14.2261V12.0908H12.214V27.5022H16.9322V19.1466C16.9322 16.9188 18.3046 15.8353 19.7047 15.8353C21.0289 15.8353 22.0559 16.6012 22.0559 19.0848V27.5022Z'
        fill='white'
      />
      <path
        d='M4.30029 7.15889C4.30029 8.76783 5.51815 9.94362 7.02043 9.94362C8.52293 9.94362 9.74079 8.76783 9.74079 7.15889C9.74079 5.55017 8.52293 4.37305 7.02043 4.37305C5.51815 4.37305 4.30029 5.55017 4.30029 7.15889Z'
        fill='white'
      />
      <path d='M4.66143 27.4903H9.37944V12.0789H4.66143V27.4903Z' fill='white' />
    </svg>
  );
};
