import { IconComponentPropsType } from '../../types';

export const ChevronRightIcon = ({
  width = '24px',
  height = '25px',
  style,
}: IconComponentPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        d='M5 12.5H19'
        stroke='#012960'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 5.5L19 12.5L12 19.5'
        stroke='#012960'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
