export const findNestedGroups = (element: Element, results: Element[]): void => {
  if (element.nodeName === 'g' && element.getAttribute('style') === 'display: block;') {
    results.push(element);
  }

  const children = Array.from(element.children);
  children.forEach((child) => {
    findNestedGroups(child, results);
  });
};
