import { styled, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { palette } from '../../../theme/foundations';

export const TypographyCSS = styled(Box)(({ theme }) => {
  return {
    color: palette.white,
    marginBottom: '5px',
    [theme.breakpoints.up('gxl')]: {
      fontSize: '0.73vw',
      marginBottom: '0.46vw',
    },
  };
});

export const TextFieldCSS = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.up('gxl')]: {
    '.MuiInputBase-root': {
      minHeight: '2.29vw',
      lineHeight: '48px',
    },
    'input, textarea': {
      fontSize: '1vw',
    },
    '& [placeholder]::placeholder': {
      fontSize: '1vw',
    },
    svg: {
      width: '1.25vw',
      height: '1.25vw',
    },
  },
}));
