import { CSSProperties, TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: 'Inter',
  body1: {
    fontSize: '14px',
    lineHeight: '145%',
    fontWeight: 400,
  },
  body2: {
    fontSize: '16px',
    lineHeight: '130%',
    fontWeight: 400,
  },
  sm: {
    fontSize: '14px',
    lineHeight: '126%',
    fontWeight: 400,
  },
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    sm: CSSProperties;
  }
  interface TypographyVariantsOptions {
    sm?: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    sm: true;
  }
}
