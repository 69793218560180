import { PopperPlacementType } from '@mui/base/Popper/Popper.types';

export const getPopperPlacement = (index: number): PopperPlacementType => {
  switch (index) {
    case 0: // CENTER
      return 'bottom';
    case 1: // Entwickler
      return 'top';
    case 2: // EIGENTÜMER
      return 'top';
    case 3: // BANKEN
      return 'bottom';
    default:
      return 'bottom';
  }
};
