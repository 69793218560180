import { Drawer, styled } from '@mui/material';
import { DRAWER_WIDTH } from '../../constants';
import { palette } from '../../theme/foundations';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '96px',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    minHeight: 'auto',
  },
  [theme.breakpoints.up('gxl')]: {
    minHeight: '5vw',
    p: {
      fontSize: '1vw',
    },
    svg: {
      width: '1.4vw',
      height: '1.4vw',
    },
  },
}));

export const DrawerWrapper = styled(Drawer)(({ theme }) => ({
  width: '100%',
  maxWidth: DRAWER_WIDTH + 'vw',
  flexShrink: 0,
  height: 'fit-content',
  '& .MuiDrawer-paper': {
    overflow: 'auto',
    color: palette.white,
    padding: '0px 48px 24px 48px',
    width: '100%',
    minWidth: '509px',
    maxWidth: DRAWER_WIDTH + 'vw',
    height: 'fit-content',
    maxHeight: '100vh',
    background: palette.blue600,
    borderLeft: `2px solid`,
    borderBottom: `2px solid`,
    borderColor: palette.blue400,
    boxShadow: '-21px 0px 40px 0px rgba(0, 16, 37, 0.16)',
    [theme.breakpoints.down('md')]: {
      padding: '10px',
      maxWidth: 'initial',
      minWidth: 'auto',
    },
    [theme.breakpoints.up('gxl')]: {
      maxWidth: '29vw',
      padding: '0px 2.5vw 1.25vw 2.5vw',
    },
  },
}));
