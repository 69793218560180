import { Stack, styled, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const Wrapper = styled(Stack)(({ theme }) => ({
  padding: '24px 0',
  [theme.breakpoints.up('gxl')]: {
    padding: '1.25vw 0',
  },
}));

export const FormCSS = styled(Stack)(({ theme }) => ({
  gap: '16px',
  [theme.breakpoints.up('gxl')]: {
    gap: '0.83vw',
  },
}));

export const ProjektentwicklerFormTitleCSS = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('gxl')]: {
    fontSize: '0.73vw',
  },
}));

export const ForgotBtn = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  cursor: 'pointer',
  textDecoration: 'underline',
  height: 'fit-content',
  fontSize: '16px',
  [theme.breakpoints.up('gxl')]: {
    fontSize: '0.83vw',
  },
}));

export const TabsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '61px',
  [theme.breakpoints.up('gxl')]: {
    height: '3.18vw',
  },
}));

export const RowButtonCSS = styled(Stack)(({ theme }) => ({
  marginTop: '28px',
  marginBottom: '85px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.up('gxl')]: {
    marginTop: '1.46vw',
    marginBottom: '4.1vw',
  },
}));

export const LoadingButtonCSS = styled(LoadingButton)(({ theme }) => ({
  marginTop: '28px',
  [theme.breakpoints.up('gxl')]: {
    marginTop: '1.46vw',
    fontSize: '0.94vw',
    padding: '0.57vw 1.67vw',
    height: 'auto',
    maxHeight: '2.55vw',
    gap: '0.36vw',
    svg: {
      width: '1.25vw',
      height: '1.25vw',
    },
  },
}));
