import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { palette } from '../../theme/foundations';

interface WrapperProps extends BoxProps {
  coordinates: {
    width: number;
    height: number;
    top: number;
    right: number;
  };
}

export const Wrapper = styled(Box)<WrapperProps>(({ coordinates }) => {
  const { width, height, top, right } = coordinates;
  return {
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    display: 'flex',
    position: 'absolute',
    borderRadius: '50%',
    cursor: 'pointer',
    width: width + 'px',
    height: height + 'px',
    top: top + 'px',
    right: right + 'px',
    border: `3px solid ${palette.white}`,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: '.2s',
    '&:hover': {
      opacity: 1,
      transition: '.2s',
    },
  };
});

export const ShineCSS = styled(Box)({
  width: '78px',
  height: '78px',
  background:
    'radial-gradient(40.85% 40.85% at 50.00% 50.00%, #FFF 0%, rgba(13, 168, 255, 0.00) 100%), radial-gradient(50% 50.00% at 50% 50.00%, #0079FF 0%, rgba(46, 104, 169, 0.00) 100%)',
  boxShadow: '0px 4px 17px 0px rgba(44, 179, 255, 0.92)',
  filter: 'blur(11.5px)',
});
