export const palette = {
  primary: {
    main: '#FFFFFF',
  },
  secondary: {
    main: '#002960',
  },
  white: '#ffffff',
  white100: '#D6E7FF',
  blue300: '#48a7ff',
  blue400: '#335988',
  blue500: '#335786',
  blue600: '#012960',
  gray500: '#867e7e',
  error: {
    main: '#f44336',
  },
  text: {
    primary: '#FFFFFF',
  },
  action: {
    disabled: '#867e7e',
  },
};
