import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { palette } from '../../../theme/foundations';

export const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

interface ButtonTabsProps extends BoxProps {
  isactive: string;
}

export const ButtonTabs = styled(Box)<ButtonTabsProps>(({ isactive, theme }) => {
  const isActive = isactive === 'true';
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '10px',
    cursor: 'pointer',
    borderBottom: isActive ? `1px solid ${palette.white}` : 'none',
    background: isActive
      ? 'linear-gradient(0deg, rgba(199, 173, 255, 0.10) 0%, rgba(199, 173, 255, 0.00) 100%)'
      : 'initial',
    [theme.breakpoints.up('gxl')]: {
      padding: '0.52vw',
      p: {
        fontSize: '1vw',
      },
    },
  };
});
