import { Box, Snackbar, Typography } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAlertContext } from 'context';
import { forwardRef } from 'react';

export const SnackbarCustom = () => {
  const { open, onCloseAlert, content } = useAlertContext();
  const { error, title, subTitle } = content || {};
  const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        width: 'fit-content',
        '.MuiPaper-root': {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          '& .MuiAlert-icon': { padding: 0, margin: 0 },
          '& .MuiAlert-action': {
            padding: 0,
            marginInlineEnd: '-5px',
          },
        },
      }}
      open={open}
      autoHideDuration={5000}
      onClose={onCloseAlert}
    >
      <Alert
        onClose={onCloseAlert}
        severity={error ? 'error' : 'success'}
        sx={{
          width: 'fit-content',
          maxWidth: '600px',
          background: error ? 'rgba(240,55,88,0.8)' : 'rgba(70,99,244,0.8)',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {title && <Typography variant={'body2'}>{title}</Typography>}
          {subTitle && <Typography>{subTitle}</Typography>}
        </Box>
      </Alert>
    </Snackbar>
  );
};
