import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { palette, typography } from '../../../theme/foundations';

export const BoxFadeCSS = styled(Box)(({ theme }) => {
  return {
    borderRadius: '6px',
    padding: '20px',
    width: '261.51px',
    backgroundColor: palette.primary.main,
    fontFamily: typography.fontFamily,
    [theme.breakpoints.up('gxl')]: {
      width: 'auto',
      maxWidth: '20vw',
      padding: '8%',
      borderRadius: '0.5vw',
      '.MuiTypography-root': {
        fontSize: '1vw',
        overflowWrap: 'anywhere',
      },
    },
  };
});
