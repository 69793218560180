import { SpinnerAnimation } from 'components';
import { Wrapper } from './styles';

export const Home = () => {
  return (
    <Wrapper>
      <SpinnerAnimation />
    </Wrapper>
  );
};
