import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type BankRegistrationInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type DeveloperRegistrationInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  message: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  website: Scalars['String']['input'];
};

export type GuarantorRegistrationInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  login: Scalars['Boolean']['output'];
  registerBank: Scalars['Boolean']['output'];
  registerDeveloper: Scalars['Boolean']['output'];
  registerGuarantor: Scalars['Boolean']['output'];
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationRegisterBankArgs = {
  input: BankRegistrationInput;
};

export type MutationRegisterDeveloperArgs = {
  input: DeveloperRegistrationInput;
};

export type MutationRegisterGuarantorArgs = {
  input: GuarantorRegistrationInput;
};

export type Query = {
  __typename?: 'Query';
  healthCheck: Scalars['String']['output'];
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = { __typename?: 'Mutation'; login: boolean };

export type RegisterBankMutationVariables = Exact<{
  input: BankRegistrationInput;
}>;

export type RegisterBankMutation = { __typename?: 'Mutation'; registerBank: boolean };

export type RegisterDeveloperMutationVariables = Exact<{
  input: DeveloperRegistrationInput;
}>;

export type RegisterDeveloperMutation = { __typename?: 'Mutation'; registerDeveloper: boolean };

export type RegisterGuarantorMutationVariables = Exact<{
  input: GuarantorRegistrationInput;
}>;

export type RegisterGuarantorMutation = { __typename?: 'Mutation'; registerGuarantor: boolean };

export type QueryQueryVariables = Exact<{ [key: string]: never }>;

export type QueryQuery = { __typename?: 'Query'; healthCheck: string };

export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input)
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RegisterBankDocument = gql`
  mutation RegisterBank($input: BankRegistrationInput!) {
    registerBank(input: $input)
  }
`;
export type RegisterBankMutationFn = Apollo.MutationFunction<
  RegisterBankMutation,
  RegisterBankMutationVariables
>;

/**
 * __useRegisterBankMutation__
 *
 * To run a mutation, you first call `useRegisterBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerBankMutation, { data, loading, error }] = useRegisterBankMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterBankMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterBankMutation, RegisterBankMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterBankMutation, RegisterBankMutationVariables>(
    RegisterBankDocument,
    options,
  );
}
export type RegisterBankMutationHookResult = ReturnType<typeof useRegisterBankMutation>;
export type RegisterBankMutationResult = Apollo.MutationResult<RegisterBankMutation>;
export type RegisterBankMutationOptions = Apollo.BaseMutationOptions<
  RegisterBankMutation,
  RegisterBankMutationVariables
>;
export const RegisterDeveloperDocument = gql`
  mutation RegisterDeveloper($input: DeveloperRegistrationInput!) {
    registerDeveloper(input: $input)
  }
`;
export type RegisterDeveloperMutationFn = Apollo.MutationFunction<
  RegisterDeveloperMutation,
  RegisterDeveloperMutationVariables
>;

/**
 * __useRegisterDeveloperMutation__
 *
 * To run a mutation, you first call `useRegisterDeveloperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDeveloperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerDeveloperMutation, { data, loading, error }] = useRegisterDeveloperMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterDeveloperMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterDeveloperMutation,
    RegisterDeveloperMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterDeveloperMutation, RegisterDeveloperMutationVariables>(
    RegisterDeveloperDocument,
    options,
  );
}
export type RegisterDeveloperMutationHookResult = ReturnType<typeof useRegisterDeveloperMutation>;
export type RegisterDeveloperMutationResult = Apollo.MutationResult<RegisterDeveloperMutation>;
export type RegisterDeveloperMutationOptions = Apollo.BaseMutationOptions<
  RegisterDeveloperMutation,
  RegisterDeveloperMutationVariables
>;
export const RegisterGuarantorDocument = gql`
  mutation RegisterGuarantor($input: GuarantorRegistrationInput!) {
    registerGuarantor(input: $input)
  }
`;
export type RegisterGuarantorMutationFn = Apollo.MutationFunction<
  RegisterGuarantorMutation,
  RegisterGuarantorMutationVariables
>;

/**
 * __useRegisterGuarantorMutation__
 *
 * To run a mutation, you first call `useRegisterGuarantorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterGuarantorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerGuarantorMutation, { data, loading, error }] = useRegisterGuarantorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterGuarantorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterGuarantorMutation,
    RegisterGuarantorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterGuarantorMutation, RegisterGuarantorMutationVariables>(
    RegisterGuarantorDocument,
    options,
  );
}
export type RegisterGuarantorMutationHookResult = ReturnType<typeof useRegisterGuarantorMutation>;
export type RegisterGuarantorMutationResult = Apollo.MutationResult<RegisterGuarantorMutation>;
export type RegisterGuarantorMutationOptions = Apollo.BaseMutationOptions<
  RegisterGuarantorMutation,
  RegisterGuarantorMutationVariables
>;
export const QueryDocument = gql`
  query Query {
    healthCheck
  }
`;

/**
 * __useQueryQuery__
 *
 * To run a query within a React component, call `useQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryQuery, QueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryQuery, QueryQueryVariables>(QueryDocument, options);
}
export function useQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryQuery, QueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryQuery, QueryQueryVariables>(QueryDocument, options);
}
export type QueryQueryHookResult = ReturnType<typeof useQueryQuery>;
export type QueryLazyQueryHookResult = ReturnType<typeof useQueryLazyQuery>;
export type QueryQueryResult = Apollo.QueryResult<QueryQuery, QueryQueryVariables>;
