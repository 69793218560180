import { Footer, Header } from 'components';
import { Outlet } from 'react-router-dom';

import { Main, Wrapper } from './styled';
import { DrawerCustom } from '../DrawerCustom/DrawerCustom';
import { useDrawerContext } from '../../context';

export const Layout = () => {
  const { openDrawer, handleDrawerOpen, handleDrawerClose } = useDrawerContext();

  return (
    <Wrapper>
      <Header onOpenDrawer={handleDrawerOpen} />
      <DrawerCustom openDrawer={openDrawer} onDrawerClose={handleDrawerClose} />
      <Main open={openDrawer}>
        <Outlet />
      </Main>
      <Footer />
    </Wrapper>
  );
};
