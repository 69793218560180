import { ButtonProps, styled } from '@mui/material';
import Button from '@mui/material/Button';
import { palette } from '../../theme/foundations';

interface IButtonCSS extends ButtonProps {
  isopen: 'true' | 'false';
}

export const ButtonCSS = styled(Button)<IButtonCSS>(({ isopen, theme }) => {
  const isOpen = isopen === 'true';
  return {
    backgroundColor: isOpen ? palette.white100 : palette.blue600,
    color: isOpen ? palette.blue600 : palette.primary.main,
    '&:hover': {
      background: isOpen
        ? palette.white100
        : 'var(--button, linear-gradient(180deg, rgba(0, 121, 255, 0.31) 0%, rgba(46, 104, 169, 0.00) 100%), linear-gradient(149deg, rgba(255, 255, 255, 0.14) 0%, rgba(13, 168, 255, 0.00) 100%), #002960)',
    },
    [theme.breakpoints.up('gxl')]: {
      height: 'auto',
      maxHeight: '1.77vw',
      fontSize: '0.83vw',
      padding: '0.31vw 0.52vw',
      svg: {
        width: '1.04vw',
        height: '0.83vw',
      },
    },
  };
});
