import { CssBaseline, ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';

import router from './router';
import { theme } from './theme';
import { AlertProvider, DrawerProvider } from './context';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client';

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DrawerProvider>
          <AlertProvider>
            <RouterProvider router={router} />
          </AlertProvider>
        </DrawerProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
