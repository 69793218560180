export const downloadPDF = (forPage: 'DatenschutzPage' | 'ImpressumPage') => {
  let fileUrl = '/forDownloadPdf/';
  if (forPage === 'DatenschutzPage') {
    fileUrl += 'Datenschutzerklärung.pdf';
  } else {
    fileUrl += '/Impressum.pdf';
  }

  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = forPage === 'DatenschutzPage' ? 'Datenschutzerklärung.pdf' : 'Impressum.pdf';
  link.target = '_blank';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
